import React from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'

import SectionWrapper from 'components/SectionWrapper'
import DecoratedTitle from 'components/DecoratedTitle'
import Carousel from 'components/Carousel'
import VirtualSpaceImage from 'assets/images/img-2.png'
import ConsultingImagee from 'assets/images/img-3.png'
import { colors } from 'styles'
import useStyles from './styles'

/**
 * Services page
 *
 * @returns {JSX.Element}
 */

const Services: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <SectionWrapper color={colors.transparent}>
        <DecoratedTitle title="Servicios" />

        <Grid container item style={{ maxWidth: 700 }}>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Grid
              container
              item
              direction="column"
              xs={12}
              sm={12}
              md={6}
              className={classes.container}
            >
              <Typography variant="body2" className={classes.subtitle}>
                Alquiler de espacios
              </Typography>
              <Typography variant="body2">
                Proporcionar conocimientos, técnicas y practicas vivenciales que potencien las
                habilidades y auto conocimiento del ser para el crecimiento integral, permitiéndote
                la oportunidad de sanar y alcanzar un estado de equilibrio para llevar y mantener un
                estilo de vida saludable. Mente, cuerpo y espíritu danzando en perfecta armonía con
                las leyes de la naturaleza.
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={6} style={{ justifyContent: 'flex-start' }}>
              <Carousel />
            </Grid>
          </Grid>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Hidden only={['xs', 'sm']}>
              <Grid container item md={6} className={classes.imageContainer}>
                <img src={VirtualSpaceImage} style={{ maxWidth: '100%' }} />
              </Grid>
            </Hidden>
            <Grid container item xs={12} sm={12} md={6} className={classes.container}>
              <Typography variant="body2" className={classes.subtitle}>
                Alquiler de espacio virtual
              </Typography>
              <Typography variant="body2">
                Proporcionar conocimientos, técnicas y practicas vivenciales que potencien las
                habilidades y auto conocimiento del ser para el crecimiento integral, permitiéndote
                la oportunidad de sanar y alcanzar un estado de equilibrio para llevar y mantener un
                estilo de vida saludable. Mente, cuerpo y espíritu danzando en perfecta armonía con
                las leyes de la naturaleza.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Grid container item xs={12} sm={12} md={6} className={classes.container}>
              <Typography variant="body2" className={classes.subtitle}>
                Consultoría integral
              </Typography>
              <Typography variant="body2">
                Proporcionar conocimientos, técnicas y practicas vivenciales que potencien las
                habilidades y auto conocimiento del ser para el crecimiento integral, permitiéndote
                la oportunidad de sanar y alcanzar un estado de equilibrio para llevar y mantener un
                estilo de vida saludable. Mente, cuerpo y espíritu danzando en perfecta armonía con
                las leyes de la naturaleza.
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={6} className={classes.imageContainer}>
              <img src={ConsultingImagee} style={{ maxWidth: '100%' }} />
            </Grid>
          </Grid>
        </Grid>
      </SectionWrapper>
    </Grid>
  )
}

export { Services }
