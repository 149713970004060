import { createMuiTheme } from '@material-ui/core/styles'

export const colors = {
  black: '#000000',
  white: '#ffffff',
  bolderOrange: '#DC8229',
  boldOrage: '#E39951',
  ligthOrange: '#EBB078',
  ligtherOrange: '#F2C7A0',
  pasiveOrange: '#F8ECE0',
  transparent: 'transparent',
  bolderPurle: '#924684',
  boldPurple: '#B366A7',
  ligthPurple: '#D386C9',
  ligtherPurple: '#F4A6EC',
  bolderRed: '#8F0C11',
  boldRed: '#B13F45',
  ligthRed: '#D27179',
  ligtherRed: '#F4A4AD',
  bolderYellow: '#F2CB2C',
  boldYellow: '#F3D458',
  ligthYellow: '#F3D458',
  ligtherYellow: '#F3D458',
  successGreen: '#24FF00',
  whiteOpacity: 'rgba(255, 255,255, 0.5)',
  gray: '#C4C4C4'
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.white
    },
    secondary: {
      main: colors.bolderOrange
    }
  },

  typography: {
    fontFamily: 'Nunito'
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: '5px',
        textTransform: 'none'
      }
    }
  }
})

export default theme
