import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles(() => ({
  root: {
    maxWidth: 280,
    height: 350,
    margin: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.gray,
    boxShadow: 'none',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      boxShadow:
        '-3px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      transform: 'scale(1.05)',
      '& > .MuiCardActions-root > a > button': {
        color: colors.white,
        backgroundColor: colors.bolderOrange
      }
    }
  },
  footer: {
    backgroundColor: colors.ligtherOrange,
    height: 35,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  link: {
    textDecoration: 'none'
  }
}))
