import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    container: {
      padding: 10,
      width: 300,
      height: 300,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.gray,
      boxShadow: 'none',
      '& > img': {
        transition: 'all .2s ease-in-out'
      },
      '&:hover': {
        boxShadow:
          '-3px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        '& > img': {
          transform: 'scale(1.3)'
        }
      }
    },
    title: {
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    link: {
      textDecoration: 'none'
    }
  })
)
