import About from 'scenes/About'
import Activities from 'scenes/Activities'
import Categories from 'scenes/Categories'
import Home from 'scenes/Home'
import Plans from 'scenes/Plans'
import Services from 'scenes/Services'
import sitemap from 'sitemap'

export default [
  { path: sitemap.home, name: 'Inicio', Component: Home },
  { path: sitemap.about, name: 'Nosotros', Component: About },
  { path: sitemap.services, name: 'Servicios', Component: Services },
  { path: sitemap.activities, name: 'Actividades', Component: Categories },
  { path: `${sitemap.activities}/:category`, Component: Activities },
  { path: sitemap.plans, name: 'Planes', Component: Plans }
]
