import React from 'react'
import ReactDOM from 'react-dom'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import { MuiThemeProvider } from '@material-ui/core/styles'
import MomentUtils from '@date-io/moment'
import { SWRConfig } from 'swr'

import './index.css'
import theme from 'styles'
import Router from 'components/Router'
import fetcher from 'utils/fetcher'

ReactDOM.render(
  <SWRConfig
    value={{
      fetcher
    }}
  >
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <Router />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </SWRConfig>,
  document.getElementById('root')
)
