import React, { FC } from 'react'
import { Grid, Hidden, Paper, Typography } from '@material-ui/core'

import useStyles from './styles'
interface DecoratedTitleProps {
  title: string
}

const DecoratedTitle: FC<DecoratedTitleProps> = ({ title }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <Grid container item className={classes.container}>
          <Paper className={classes.titleDecorator} />
          <Grid item style={{ alignItems: 'flex-end', display: 'flex', marginLeft: 20 }}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={['md', 'lg']}>
        <Typography variant="h5" className={classes.subtitle}>
          {title}
        </Typography>
      </Hidden>
    </>
  )
}

export default DecoratedTitle
