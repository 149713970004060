import React from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'

import useStyles from './styles'
import DecoratedTitle from 'components/DecoratedTitle'
import AboutImage from 'assets/images/quienes-somos-illustration.svg'
import MissionImage from 'assets/images/illustration_yoga.svg'
import ValuesImage from 'assets/images/illustrion-vision-valores.svg'
import { colors } from 'styles'
import Instructors from './Instructors'
import SectionWrapper from 'components/SectionWrapper'

/**
 * About page
 *
 * @returns {JSX.Element}
 */

const About: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <SectionWrapper color={colors.transparent}>
        <DecoratedTitle title="Nosotros" />

        <Grid container item style={{ maxWidth: 700 }}>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Grid container item direction="column" xs={12} sm={12} md={6}>
              <Typography variant="body2" className={classes.subtitle}>
                ¿Quiénes somos?
              </Typography>
              <Typography variant="body2">
                Somos un equipo multidisciplinario de profesionales calificados en el área de salud
                y bienestar, orientados a proporcionarte alternativas para tu crecimiento y
                desarrollo integral.
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={6} style={{ justifyContent: 'flex-start' }}>
              <img src={AboutImage} className={classes.largeImage} />
            </Grid>
          </Grid>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Hidden only={['xs', 'sm']}>
              <Grid container item md={6} style={{ justifyContent: 'flex-start' }}>
                <img src={MissionImage} style={{ maxWidth: 300 }} />
              </Grid>
            </Hidden>
            <Grid container item xs={12} sm={12} md={6}>
              <Typography variant="body2" className={classes.subtitle}>
                Misión
              </Typography>
              <Typography variant="body2">
                Proporcionar conocimientos, técnicas y practicas vivenciales que potencien las
                habilidades y auto conocimiento del ser para el crecimiento integral, permitiéndote
                la oportunidad de sanar y alcanzar un estado de equilibrio para llevar y mantener un
                estilo de vida saludable. Mente, cuerpo y espíritu danzando en perfecta armonía con
                las leyes de la naturaleza.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item style={{ marginBottom: 20, marginTop: 20 }}>
            <Grid container item xs={12} sm={12} md={6}>
              <Typography variant="body2" className={classes.subtitle}>
                Visión
              </Typography>
              <Typography variant="body2">
                Ser el primer centro de crecimiento integral en salud y bienestar a nivel Nacional e
                Internacional, expandiendo nuestros espacios y ampliando nuestras actividades para
                satisfacer las necesidades de nuestros clientes.
              </Typography>
              <Typography variant="body2" className={classes.subtitle}>
                Valores
              </Typography>
              <Typography variant="body2">
                Ser el primer centro de crecimiento integral en salud y bienestar a nivel Nacional e
                Internacional, expandiendo nuestros espacios y ampliando nuestras actividades para
                satisfacer las necesidades de nuestros clientes.
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={6}>
              <img src={ValuesImage} className={classes.largeImage} style={{ maxWidth: 600 }} />
            </Grid>
          </Grid>
        </Grid>
      </SectionWrapper>
      <SectionWrapper color={colors.pasiveOrange}>
        <DecoratedTitle title="Responsabilidad social" />

        <Grid container item style={{ maxWidth: 700, marginTop: 20, marginBottom: 20 }}>
          <Typography variant="body2">
            Proporcionar conocimientos, técnicas y practicas vivenciales que potencien las
            habilidades y auto conocimiento del ser para el crecimiento integral, permitiéndote la
            oportunidad de sanar y alcanzar un estado de equilibrio para llevar y mantener un estilo
            de vida saludable. Mente, cuerpo y espíritu danzando en perfecta armonía con las leyes
            de la naturaleza.
          </Typography>
        </Grid>
      </SectionWrapper>

      <Instructors />
    </Grid>
  )
}

export { About }
