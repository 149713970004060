import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { colors } from 'styles'

export const styles = ({ palette }: Theme): any => ({
  root: {
    borderRadius: 4,
    border: `1px solid ${colors.black}`,
    boxShadow: `0 2px 4px 0 ${colors.black}`,
    backgroundColor: 'white'
  },
  error: {
    borderColor: palette.error.main
  }
})

export const borderedInputBaseStyles = (theme: Theme): any => ({
  ...styles(theme),
  formControl: {
    'label + &': {
      marginTop: 22,
      padding: '0 10px'
    }
  }
})

export const borderedInputLabelStyles = (): any => ({
  root: {
    '&.Mui-focused': {
      fontWeight: 'bold'
    }
  },
  error: {},
  focused: {},
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
    fontWeight: 500,
    color: 'black'
  }
})

export const borderedTextFieldStylesHook = {
  useInputBase: makeStyles(borderedInputBaseStyles, {
    name: 'BorderedTextField',
    index: 1000
  }),
  useInputLabel: makeStyles(borderedInputLabelStyles, {
    name: 'BorderedTextField'
  })
}

export default makeStyles(() =>
  createStyles({
    root: {
      width: '100%'
    },
    maskIcon: {
      '&.Mui-focusVisible': {
        backgroundColor: colors.black
      }
    }
  })
)
