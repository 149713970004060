import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useSWR from 'swr'

import api from 'api'
import DecoratedTitle from 'components/DecoratedTitle'
import ActivityCategory from 'components/ActivityCategory'
import SectionWrapper from 'components/SectionWrapper'
import { colors } from 'styles'
import useStyles from './styles'
import { ICategory } from 'types/models'

/**
 * Categories page
 *
 * @returns {JSX.Element}
 */

const Categories: React.FC = (): JSX.Element => {
  const { data: categories } = useSWR(api.categories)
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <SectionWrapper color={colors.transparent}>
        <DecoratedTitle title="Actividades" />
        <Grid container item style={{ justifyContent: 'center', margin: 10, width: '100%' }}>
          <Typography style={{ marginBottom: 10 }}>
            Selecciona una de las siguientes categorías para ver todo lo que podemos ofrecerte:
          </Typography>
          {categories?.length > 0 ? (
            categories
              .sort((a: ICategory, b: ICategory) => a.id - b.id)
              .map((category: ICategory, index: number) => (
                <ActivityCategory
                  {...category}
                  key={`${index}-${category.id}`}
                  background={index % 2 !== 0 ? colors.pasiveOrange : colors.white}
                />
              ))
          ) : (
            <Typography>Cargando categorías.</Typography>
          )}
        </Grid>
      </SectionWrapper>
    </Grid>
  )
}

export { Categories }
