import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    contactForm: {
      marginTop: 20,
      width: '90%',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    submitButton: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      marginTop: 20
    },
    subtitle: {
      fontWeight: 'bold'
    }
  })
)
