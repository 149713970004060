import React, { FC } from 'react'
import { makeStyles, Button as MaterialButton } from '@material-ui/core'
import { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button'
import { colors } from 'styles'

const useStyles = makeStyles(() => ({
  containedStyles: {
    color: colors.white,
    height: 30
  },
  outlinedStyles: {
    color: colors.bolderOrange,
    height: 30
  }
}))

interface ButtonProps extends MaterialButtonProps {
  children: string
  onClick?: (e: React.MouseEvent<any>) => void
}

const Button: FC<ButtonProps> = ({ children, onClick, variant, color, style }) => {
  const classes = useStyles()

  return (
    <MaterialButton
      variant={variant ? variant : 'contained'}
      color={color ? color : 'secondary'}
      type={!onClick ? 'submit' : 'button'}
      size={onClick ? 'small' : 'medium'}
      onClick={onClick}
      className={variant === 'contained' ? classes.containedStyles : classes.outlinedStyles}
      style={style}
    >
      {children}
    </MaterialButton>
  )
}

export default Button
