import { createStyles, makeStyles } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    activeLink: {
      borderBottomWidth: 5,
      borderBottomStyle: 'solid',
      borderBottomColor: colors.bolderOrange
    },

    title: {
      textAlign: 'center',
      color: colors.black,
      fontWeight: 'bold',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    link: {
      color: colors.black,
      flexGrow: 0.1,
      textDecoration: 'none',
      height: 60
    },
    logoContainer: {
      position: 'relative',
      justifyContent: 'flex-start',
      zIndex: 1000
    },
    image: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 100,
      height: 110,
      position: 'absolute',
      borderBottomRightRadius: 70,
      borderBottomLeftRadius: 70
    },
    navigationLinks: {
      justifyContent: 'center',
      alignItems: 'center',
      height: 64
    },
    mobileLogo: {
      justifyContent: 'flex-end',
      width: 60,
      height: 60,
      marginRight: 10
    }
  })
)
