import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

import Icon from 'components/Icon'

import useStyles from './styles'

/**
 * Footer component
 *
 * @returns {JSX.Element}
 */

const Footer: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container>
        <Grid container item className={classes.container}>
          <Typography variant="body1" className={classes.textFooter}>
            Síguenos a través de nuestras redes sociales
          </Typography>
        </Grid>
        <Grid container item className={classes.container}>
          <Grid item className={classes.socialIcon}>
            <a href="https://www.instagram.com/centroebiun/">{Icon.instagram}</a>
          </Grid>
          <Grid item className={classes.socialIcon}>
            <a href="https://www.instagram.com/centroebiun/">{Icon.facebook}</a>
          </Grid>
          <Grid item className={classes.socialIcon}>
            <a href="https://www.instagram.com/centroebiun/">{Icon.twitter}</a>
          </Grid>
        </Grid>
        <Grid container item className={classes.container}>
          <Divider light className={classes.divider} />
        </Grid>

        <Grid container item className={classes.container}>
          <Typography variant="body1" className={classes.textFooter}>
            Copyright © 2020 Yiant. Todos los derechos reservados.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}

export { Footer }
