import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center'
    },
    footer: {
      padding: theme.spacing(3, 2),
      backgroundColor: colors.bolderOrange,
      justifyContent: 'center',
      width: '100%'
    },
    socialIcon: {
      padding: 10
    },
    divider: {
      minWidth: 200,
      maxWidth: 600,
      backgroundColor: colors.white,
      marginBottom: 15
    },
    textFooter: {
      color: colors.white,
      textAlign: 'center'
    }
  })
)
