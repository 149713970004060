import React, { FC } from 'react'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core'

import Img5 from 'assets/images/img-5.png'
import Reiki from 'assets/images/reiki.png'
import Yoga from 'assets/images/yoga.png'
import { colors } from 'styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& > .carousel': {
        '& > ul.control-dots': {
          '& > .dot': {
            height: 15,
            width: 15,
            [theme.breakpoints.down('md')]: {
              width: 10,
              height: 10
            }
          },
          '& > .selected': {
            backgroundColor: colors.boldOrage
          }
        }
      }
    },
    nextArrow: {
      borderRadius: '50%',
      width: 40,
      height: 40,
      borderColor: colors.bolderOrange,
      borderStyle: 'solid',
      borderWidth: 2,
      backgroundColor: colors.whiteOpacity,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
      right: '2%',
      top: '50%',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: colors.whiteOpacity
      },
      [theme.breakpoints.down('md')]: {
        width: 20,
        height: 20
      }
    },
    backArrow: {
      borderRadius: '50%',
      width: 40,
      height: 40,
      borderColor: colors.bolderOrange,
      borderStyle: 'solid',
      borderWidth: 2,
      backgroundColor: colors.whiteOpacity,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'absolute',
      left: '2%',
      top: '50%',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: colors.whiteOpacity
      },
      [theme.breakpoints.down('md')]: {
        width: 20,
        height: 20
      }
    }
  })
)

const Carousel: FC = () => {
  const classes = useStyles()

  return (
    <ResponsiveCarousel
      showThumbs={false}
      showStatus={false}
      autoPlay={false}
      infiniteLoop={true}
      showIndicators={false}
      className={classes.container}
      renderArrowNext={clickHandler => (
        <IconButton onClick={clickHandler} className={classes.nextArrow}>
          <ArrowForwardIosIcon style={{ color: colors.bolderOrange }} />
        </IconButton>
      )}
      renderArrowPrev={clickHandler => (
        <IconButton onClick={clickHandler} className={classes.backArrow}>
          <ArrowBackIosIcon style={{ color: colors.bolderOrange }} />
        </IconButton>
      )}
    >
      <div>
        <img src={Img5} />
      </div>
      <div>
        <img src={Reiki} />
      </div>
      <div>
        <img src={Yoga} />
      </div>
    </ResponsiveCarousel>
  )
}

export default Carousel
