import { createStyles, makeStyles } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 15
    },
    titleDecorator: {
      width: 30,
      height: 60,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
      backgroundColor: colors.bolderOrange
    },
    subtitle: {
      fontWeight: 'bold',
      textAlign: 'center'
    }
  })
)
