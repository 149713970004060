import React, { FC } from 'react'
import {
  InputBase,
  Select as MaterialSelect,
  MenuItem as SelectItem,
  withStyles,
  Theme
} from '@material-ui/core'
import { SelectProps as MaterialSelectProps } from '@material-ui/core/Select'

import { ISelectOption } from 'types'

const BootstrapInput = withStyles((theme: Theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

interface SelectProps extends MaterialSelectProps {
  options: ISelectOption[]
}

const Select: FC<SelectProps> = ({ options, ...props }) => {
  return (
    <MaterialSelect {...props} input={<BootstrapInput />}>
      {options.map(option => (
        <SelectItem key={option.value} value={option.value}>
          {option.label}
        </SelectItem>
      ))}
    </MaterialSelect>
  )
}

export default Select
