import { Breadcrumbs, Typography, Link as MaterialLink, Paper } from '@material-ui/core'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { IRoute } from 'types'
import useStyles from './styles'

interface IBreadcrumb {
  crumbs: IRoute[]
}

const Breadcrumb: React.FC<IBreadcrumb> = ({ crumbs }): JSX.Element => {
  const { category } = useParams<{ [key: string]: string }>()

  // Variables
  const classes = useStyles()

  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return <></>
  }

  return (
    <Paper className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.separators}>
        {crumbs.map(({ name, path }, key) => {
          const label = !name ? category : name
          return key + 1 === crumbs.length ? (
            <Typography key={key} className={classes.active}>
              {label}
            </Typography>
          ) : (
            <Link
              key={key}
              to={{
                pathname: path
              }}
              className={classes.link}
            >
              <MaterialLink component="span">{label}</MaterialLink>
            </Link>
          )
        })}
      </Breadcrumbs>
    </Paper>
  )
}

export { Breadcrumb }
