import { RouteComponentProps } from 'react-router-dom'
import { IRoute } from 'types'

/**
 * Generate Breadcrumb navigation for a router
 *
 * @param {Route[]} routes
 * @param {RouteComponentProps} props
 *
 * @returns {IRoute[]} resulted routes based on path
 */
export const generateCrumbs = (routes: IRoute[], props: RouteComponentProps): IRoute[] =>
  routes
    .filter(({ path }) => props.match.path.includes(path))
    .map(({ path, ...rest }) => {
      return {
        ...rest,
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce(
              (path, param) =>
                path.replace(`:${param}`, (props.match.params as { [key: string]: string })[param]),
              path
            )
          : path
      }
    })
