import React, { FC } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import sitemap from 'sitemap'
import { ICategory } from 'types/models'

import useStyles from './styles'

interface ActivityCategoryProps extends ICategory {
  background: string
}

const ActivityCategory: FC<ActivityCategoryProps> = ({
  background,
  name,
  image,
  slug,
  color,
  id
}) => {
  const classes = useStyles()

  return (
    <Link
      to={{
        pathname: `${sitemap.activities}/${slug}`,
        state: {
          id,
          name,
          color
        }
      }}
      className={classes.link}
    >
      <Paper
        className={classes.container}
        style={{
          backgroundColor: background
        }}
        elevation={0}
      >
        <Typography variant="subtitle1" className={classes.title}>
          {name}
        </Typography>
        <img src={image} style={{ width: '100%' }} />
      </Paper>
    </Link>
  )
}

export default ActivityCategory
