import React, { FC } from 'react'
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

interface ITestimonialCard {
  title: string
  firstName: string
  lastName: string
  comment: string
  avatar: string
}

const TestimonialCard: FC<ITestimonialCard> = ({ title, firstName, lastName, comment, avatar }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <Grid container className={classes.avatarContainer}>
        <Avatar aria-label="avatar" className={classes.avatar} src={avatar} />
      </Grid>

      <CardContent className={classes.contentContainer}>
        <Typography variant="body2" color="textSecondary" component="p">
          {comment}
        </Typography>

        <Grid container className={classes.dataContainer}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.personData}
          >
            {`${firstName} ${lastName}`}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.personData}
          >
            {title}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TestimonialCard
