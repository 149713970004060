import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'

/**
 * NotFound Page
 *
 * @returns {JSX.Element}
 */
const NotFound: React.FC = (): JSX.Element => {
  // Component
  return (
    <Grid container alignItems="center" justify="center" style={{ height: '100vh' }}>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Grid item container xs={12} justify="center">
        <Grid item xs={12} container justify="center">
          <Typography variant="h2" component="h1">
            Error 404
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { NotFound }
