import React, { ChangeEvent, FC } from 'react'
import { Chip, Grid, Hidden, Typography } from '@material-ui/core'

import Button from 'components/Button'
import Carousel from 'components/Carousel'
import { colors } from 'styles'
import useDeviceType from 'hooks/useDeviceType'
import useStyles, { Accordion, AccordionDetails, AccordionSummary } from './styles'
import sitemap from 'sitemap'
import { IActivity } from 'types'

interface ActivityProps extends IActivity {
  open: number | boolean
  category: string
  description: string
  capacity: number
  color: string
  onExpand: (
    panel: number
  ) => (event: ChangeEvent<{ [key: string]: any }>, newExpanded: boolean) => void
}

const Activity: FC<ActivityProps> = ({
  id,
  title,
  open,
  category,
  description,
  capacity,
  color,
  onExpand
}) => {
  const isMobile = useDeviceType()
  const classes = useStyles()

  return (
    <Accordion square expanded={open === id || isMobile} onChange={onExpand(id)}>
      <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`}>
        <Grid container item style={{ alignItems: 'center', width: 'auto', marginRight: 20 }}>
          <Typography>{title}</Typography>
        </Grid>
        <Chip label={category} style={{ backgroundColor: color, color: colors.white }} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container item xs={12} sm={12} md={6} style={{ paddingRight: 15 }}>
            <Grid container item>
              <Typography>{description}</Typography>
            </Grid>
            <Grid container item className={classes.actions}>
              <Typography>
                <strong>Estado:</strong>
                {` ${capacity > 0 ? '' : 'No'} Disponible`}
              </Typography>
              <span
                style={{
                  marginLeft: 10,
                  borderRadius: '50%',
                  width: 10,
                  height: 10,
                  backgroundColor: capacity > 0 ? colors.successGreen : colors.boldRed
                }}
              />
            </Grid>
            <Grid container item style={{ marginTop: 20 }}>
              <a href={`${sitemap.home}#contacto`} className={classes.link}>
                <Button variant="outlined" style={{ color: colors.bolderOrange }}>
                  Contáctanos
                </Button>
              </a>
            </Grid>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid container item xs={12} sm={12} md={6} style={{ justifyContent: 'center' }}>
              <Carousel />
            </Grid>
          </Hidden>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default Activity
