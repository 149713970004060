import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    actions: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 20
    },
    link: {
      textDecoration: 'none'
    }
  })
)

export const Accordion = withStyles({
  root: {
    border: `1px solid ${colors.gray}`,
    boxShadow: 'none',
    transition: 'all .2s ease-in-out',
    borderRadius: 5,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    },
    '&:hover': {
      boxShadow:
        '-3px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
    }
  },
  expanded: {
    boxShadow:
      '-3px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
  }
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    minHeight: 70,
    '&$expanded': {
      minHeight: 70
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)
