export const API_BASE = process.env.REACT_APP_API_URL
export const API_BASE_AUTH = process.env.REACT_APP_API_URL_AUTH

const api = {
  login: `${API_BASE_AUTH}/login/`,
  activities: `${API_BASE}/activities/`,
  plans: `${API_BASE}/plans/`,
  testimonies: `${API_BASE}/testimonies/`,
  providers: `${API_BASE}/providers/`,
  categories: `${API_BASE}/categories/`,
  send: `${API_BASE}/contact/`
}

export default api
