import React from 'react'
import { Helmet } from 'react-helmet'

/**
 * Wrapper for react-helmet metadata
 *
 * @param {{ children: JSX.Element }} { children }
 * @returns
 */
const App: React.FC<{ children: JSX.Element }> = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  // Component
  return (
    <>
      <Helmet titleTemplate="%s | Ebiun." defer={false} defaultTitle="Ebiun.">
        <title>Ebiun.</title>
        <meta name="title" content="Ebiun." />
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://centroebiun.com${location.pathname}`} />
        <meta property="og:title" content="Ebiun." />
      </Helmet>
      {children}
    </>
  )
}

export { App }
