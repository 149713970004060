import { makeStyles } from '@material-ui/core'

import { colors } from 'styles'

export default makeStyles(() => ({
  root: {
    maxWidth: 280,
    height: 350,
    margin: 10
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.bolderOrange,
    width: 70,
    height: 70
  },
  dataContainer: {
    margintTop: 20
  },
  personData: {
    width: '100%'
  },
  contentContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  avatarContainer: {
    padding: 16,
    justifyContent: 'center'
  }
}))
