import React, { FC } from 'react'
import { Avatar, Grid, Typography } from '@material-ui/core'

interface AvatarBadgeProps {
  avatar: string
  firstName: string
  lastName: string
  title: string
}

const AvatarBadge: FC<AvatarBadgeProps> = ({ avatar, firstName, lastName, title }) => {
  return (
    <Grid
      style={{
        padding: 10,
        marginRight: 10,
        marginLeft: 10,
        width: 175,
        height: 175,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
      }}
    >
      <Avatar style={{ width: 100, height: 100 }} src={avatar} />
      <Grid container item style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Typography>{`${firstName} ${lastName}`}</Typography>
        <Typography>{title}</Typography>
      </Grid>
    </Grid>
  )
}

export default AvatarBadge
