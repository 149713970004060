import React from 'react'
import { Grid } from '@material-ui/core'

import DecoratedTitle from 'components/DecoratedTitle'
import TestimonialCard from 'components/TestimonialCard'
import SectionWrapper from 'components/SectionWrapper'
import { colors } from 'styles'
import faker from 'faker'

const testimonials = [
  {
    title: faker.name.jobTitle(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    comment: faker.lorem.paragraph(),
    avatar: 'https://www.placecage.com/200/200'
  },
  {
    title: faker.name.jobTitle(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    comment: faker.lorem.paragraph(),
    avatar: 'https://www.placecage.com/g/200/200'
  },
  {
    title: faker.name.jobTitle(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    comment: faker.lorem.paragraph(),
    avatar: 'https://www.placecage.com/c/200/200'
  }
]

/**
 * Testimonials section
 *
 * @returns {JSX.Element}
 */

const Testimonials: React.FC = (): JSX.Element => {
  return (
    <SectionWrapper color={colors.pasiveOrange} sectionId="testimonios">
      <DecoratedTitle title="Testimonios" />
      <Grid container item style={{ justifyContent: 'center' }}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export { Testimonials }
