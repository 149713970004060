import React, { useState, ChangeEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import useSWR from 'swr'

import DecoratedTitle from 'components/DecoratedTitle'
import SectionWrapper from 'components/SectionWrapper'
import { colors } from 'styles'
import useStyles from './styles'
import Activity from 'components/Activity'
import { IActivity, ICategory } from 'types'
import api from 'api'

/**
 * Activities page
 *
 * @returns {JSX.Element}
 */

const Activities: React.FC = (): JSX.Element => {
  const location = useLocation()
  const state = location.state as Pick<ICategory, 'id' | 'name' | 'color'>
  const { data: activities } = useSWR(`${api.activities}?category__id=${state.id}`)

  const classes = useStyles()
  const [expanded, setExpanded] = useState<number | boolean>(0)

  const handleChange = (panel: number) => (
    event: ChangeEvent<{ [key: string]: any }>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Grid container className={classes.root}>
      <SectionWrapper color={colors.transparent}>
        <DecoratedTitle title={state.name} />
        <Grid container style={{ marginTop: 15 }}>
          {activities?.length > 0 ? (
            activities.map((activity: IActivity, index: number) => (
              <Activity
                {...activity}
                key={index}
                open={expanded}
                onExpand={handleChange}
                color={state.color}
                category={state.name}
              />
            ))
          ) : (
            <Typography>No hay actividades registradas para esta categoría.</Typography>
          )}
        </Grid>
      </SectionWrapper>
    </Grid>
  )
}

export { Activities }
