import React, { FC } from 'react'
import { Container, Grid } from '@material-ui/core'

import useStyles from './styles'

interface SectionWrapperProps {
  sectionId?: string
  color: string
  children: JSX.Element[] | JSX.Element
}

const SectionWrapper: FC<SectionWrapperProps> = ({ sectionId, color, children }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      id={sectionId}
      className={classes.sectionContainer}
      style={{ backgroundColor: color }}
    >
      <Container className={classes.contentContainer}>{children}</Container>
    </Grid>
  )
}

export default SectionWrapper
