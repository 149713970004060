import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    sectionContainer: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.up('md')]: {
        paddingTop: 0
      }
    },
    contentContainer: {
      maxWidth: 730
    }
  })
)
