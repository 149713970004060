import React from 'react'
import { Grid } from '@material-ui/core'

import SectionWrapper from 'components/SectionWrapper'
import DecoratedTitle from 'components/DecoratedTitle'
import { colors } from 'styles'
import AvatarBadge from 'components/AvatarBadge'
import faker from 'faker'

const mockedInstructor = () => ({
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  avatar: 'https://picsum.photos/200/300',
  title: faker.lorem.word()
})

/**
 * Instructors section
 *
 * @returns {JSX.Element}
 */

const Instructors: React.FC = (): JSX.Element => {
  return (
    <SectionWrapper color={colors.transparent}>
      <DecoratedTitle title="Instructores" />

      <Grid
        container
        item
        style={{ maxWidth: 800, justifyContent: 'center', marginTop: 20, marginBottom: 20 }}
      >
        {new Array(6).fill(mockedInstructor()).map((instructor, index) => (
          <AvatarBadge key={index} {...instructor} />
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export { Instructors }
