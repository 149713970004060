import React from 'react'

import { SvgIcon } from '@material-ui/core'

import { ReactComponent as InstagramIcon } from 'assets/icons/ig_icon.svg'
import { ReactComponent as FacebookIcon } from 'assets/icons/fb_icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/tw_icon.svg'

import { colors } from 'styles'

const Icon = (props: { icon: any }): JSX.Element => {
  return (
    <SvgIcon
      component={props.icon}
      fontSize="small"
      htmlColor={colors.white}
      className="custom-icon"
    />
  )
}

export default {
  instagram: <Icon icon={InstagramIcon} />,
  facebook: <Icon icon={FacebookIcon} />,
  twitter: <Icon icon={TwitterIcon} />
}
