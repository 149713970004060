import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core'

import routes from 'routes'
import NotFound from 'scenes/NotFound'
import { generateCrumbs } from 'utils/breadcrumb'
import App from 'components/App'
import Breadcrumb from 'components/Breadcrumb'
import Footer from 'components/Footer'
import Header from 'components/Header'
import useDeviceType from 'hooks/useDeviceType'
import sitemap from 'sitemap'

const Router = (): JSX.Element => {
  const isMobile = useDeviceType()

  return (
    <BrowserRouter>
      <App>
        <Switch>
          <Redirect from="/index.html" to={sitemap.home} />
          {routes.map(({ path, name, Component, ...rest }, key) => {
            return (
              <Route
                exact={true}
                path={path}
                key={key}
                render={props => {
                  const crumbs = generateCrumbs(routes, props)

                  return (
                    <Container
                      style={{
                        margin: 0,
                        padding: 0,
                        width: '100%',
                        maxWidth: '100%',
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      <Header />
                      <main style={{ minHeight: '100vh' }}>
                        {isMobile && <Breadcrumb crumbs={crumbs} />}
                        <Component {...rest} />
                      </main>
                      <Footer />
                    </Container>
                  )
                }}
              />
            )
          })}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </App>
    </BrowserRouter>
  )
}

export { Router }
