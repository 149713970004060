import humps from 'humps'

export default async (url: string, method = 'GET', data?: { [key: string]: any }): Promise<any> => {
  try {
    const response = await fetch(url, {
      method,
      body: data && JSON.stringify(humps.decamelizeKeys(data)),
      headers: data && {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if (response.ok) {
      const data = await response.json()
      return humps.camelizeKeys(data)
    } else {
      const body = await response.json()
      const message =
        Object.entries(body).length > 0 ? Object.entries(body)[0][1] : response.statusText

      return { error: { statusText: message, body }, status: response.status }
    }
  } catch (error) {
    return { error: { statusText: error }, status: 500 }
  }
}
