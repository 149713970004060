import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    root: {},
    subtitle: {
      fontWeight: 'bold'
    }
  })
)
