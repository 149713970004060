import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import MuiTextField, { StandardTextFieldProps } from '@material-ui/core/TextField'

import useStyles, { borderedTextFieldStylesHook } from './styles'
import { colors } from 'styles'

interface IInputFieldProps extends StandardTextFieldProps {
  errorMessage?: string
}

/**
 * Generic Input Field Wrapper
 *
 * @param {IInputFieldProps} props
 * @returns {JSX.Element}
 */
const InputField: React.FC<IInputFieldProps> = ({ errorMessage, type, ...rest }): JSX.Element => {
  // Hooks
  const inputBaseStyles = borderedTextFieldStylesHook.useInputBase()
  const inputLabelStyles = borderedTextFieldStylesHook.useInputLabel()
  // State
  const [values, setValues] = React.useState<{ showPassword: boolean }>({
    showPassword: false
  })
  // Methods
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  // Variables
  const classes = useStyles()
  // Component
  return (
    <div style={{ width: '100%', marginBottom: 16 }}>
      <MuiTextField
        {...(errorMessage && { helperText: errorMessage })}
        {...rest}
        classes={{ root: classes.root }}
        type={values.showPassword ? 'text' : type}
        InputLabelProps={{
          shrink: true,
          classes: inputLabelStyles,
          style: { color: colors.black }
        }}
        InputProps={{
          classes: inputBaseStyles,
          disableUnderline: true,
          ...(type === 'password' && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="small"
                  className={classes.maskIcon}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          })
        }}
      />
    </div>
  )
}

export { InputField }
