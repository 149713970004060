import React from 'react'
import { NavLink } from 'react-router-dom'
import { AppBar, Grid, Hidden, Paper, Toolbar, Typography } from '@material-ui/core'

import MobileHeader from 'components/MobileHeader'
import logo from 'assets/images/logo-ebiun.svg'
import sitemap from 'sitemap'

import useStyles from './styles'

/**
 * Header component
 *
 * @returns {JSX.Element}
 */

const Header: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <AppBar component="header" position="static" elevation={3}>
      <Toolbar>
        <Hidden only={['xs', 'sm']}>
          <Grid container>
            <Grid container item className={classes.logoContainer}>
              <NavLink to="/">
                <Paper elevation={3} className={classes.image}>
                  <img src={logo} alt="Ebiun logo." />
                </Paper>
              </NavLink>
            </Grid>
            <Grid container item className={classes.navigationLinks}>
              <NavLink
                to={sitemap.about}
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                <Typography variant="body2" className={classes.title}>
                  Nosotros
                </Typography>
              </NavLink>
              <NavLink
                to={sitemap.services}
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                <Typography variant="body2" className={classes.title}>
                  Servicios
                </Typography>
              </NavLink>
              <NavLink
                to={sitemap.activities}
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                <Typography variant="body2" className={classes.title}>
                  Actividades
                </Typography>
              </NavLink>
              <NavLink
                to={sitemap.plans}
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                <Typography variant="body2" className={classes.title}>
                  Planes
                </Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Hidden>
        <MobileHeader />
      </Toolbar>
    </AppBar>
  )
}

export { Header }
