import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import SyncIcon from '@material-ui/icons/Sync'
import EventIcon from '@material-ui/icons/Event'
import BarChartIcon from '@material-ui/icons/BarChart'

import logo from 'assets/images/logo-ebiun.svg'
import sitemap from 'sitemap'

import useStyles from './styles'

/**
 * Mobile Header component
 *
 * @returns {JSX.Element}
 */

const MobileHeader: React.FC = (): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setOpenDrawer(open)
  }

  const classes = useStyles()

  return (
    <Hidden only={['md', 'lg']}>
      <Grid container className={classes.mobileLogo}>
        <NavLink to="/">
          <img src={logo} alt="Ebiun logo." style={{ width: '80%' }} />
        </NavLink>
      </Grid>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        style={{ width: '30vh' }}
      >
        <List style={{ marginTop: 20 }}>
          <NavLink to={sitemap.about} className={classes.link} activeClassName={classes.activeLink}>
            <ListItem button>
              <ListItemIcon className={classes.iconContainer}>
                <AccountBalanceIcon className={classes.linkIcon} />
              </ListItemIcon>
              <ListItemText primary="Nosotros" />
            </ListItem>
          </NavLink>
          <NavLink
            to={sitemap.services}
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItem button>
              <ListItemIcon className={classes.iconContainer}>
                <SyncIcon className={classes.linkIcon} />
              </ListItemIcon>
              <ListItemText primary="Servicios" />
            </ListItem>
          </NavLink>

          <NavLink
            to={sitemap.activities}
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItem button>
              <ListItemIcon className={classes.iconContainer}>
                <EventIcon className={classes.linkIcon} />
              </ListItemIcon>
              <ListItemText primary="Actividades" />
            </ListItem>
          </NavLink>
          <NavLink to={sitemap.plans} className={classes.link} activeClassName={classes.activeLink}>
            <ListItem button>
              <ListItemIcon className={classes.iconContainer}>
                <BarChartIcon className={classes.linkIcon} />
              </ListItemIcon>
              <ListItemText primary="Planes" />
            </ListItem>
          </NavLink>
        </List>
      </Drawer>
    </Hidden>
  )
}

export { MobileHeader }
