import { createStyles, makeStyles } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    container: {
      color: colors.white,
      backgroundColor: colors.bolderOrange,
      height: 40,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 0
    },
    link: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline' }
    },
    separators: {
      color: colors.white
    },
    active: {
      fontWeight: 'bold',
      color: colors.white
    }
  })
)
