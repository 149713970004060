import React, { FC } from 'react'
import { Card, CardActions, CardContent, Paper, Typography } from '@material-ui/core'

import Button from 'components/Button'
import sitemap from 'sitemap'
import { IPlan } from 'types'

import useStyles from './styles'

const PlanCard: FC<IPlan> = ({ title, description }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent style={{ height: 237 }}>
        <Typography gutterBottom variant="h5" component="h2" style={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <a href={`${sitemap.home}#contacto`} className={classes.link}>
          <Button variant="outlined">Contáctanos</Button>
        </a>
      </CardActions>
      <Paper className={classes.footer} elevation={0} />
    </Card>
  )
}

export default PlanCard
