import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useSWR from 'swr'

import DecoratedTitle from 'components/DecoratedTitle'
import PlanCard from 'components/PlanCard'
import SectionWrapper from 'components/SectionWrapper'
import useStyles from './styles'
import { colors } from 'styles'
import api from 'api'
import { IPlan } from 'types'

/**
 * Plans page
 *
 * @returns {JSX.Element}
 */

const Plans: React.FC = (): JSX.Element => {
  const { data: plans } = useSWR(api.plans)

  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <SectionWrapper color={colors.transparent}>
        <DecoratedTitle title="Planes" />

        <Grid container item style={{ maxWidth: 700, justifyContent: 'center', marginTop: 15 }}>
          {plans?.length > 0 ? (
            plans.map(
              (plan: IPlan, index: number) =>
                plan.isActive && <PlanCard {...plan} key={`${index}-${plan.id}`} />
            )
          ) : (
            <Typography>No hay planes registrados.</Typography>
          )}
        </Grid>
      </SectionWrapper>
    </Grid>
  )
}

export { Plans }
