import { createStyles, makeStyles } from '@material-ui/core/styles'

import { colors } from 'styles'

export default makeStyles(() =>
  createStyles({
    activeLink: {
      borderBottomWidth: 5,
      borderBottomStyle: 'solid',
      borderBottomColor: colors.bolderOrange
    },

    link: {
      color: colors.black,
      flexGrow: 0.1,
      textDecoration: 'none',
      height: 60
    },
    mobileLogo: {
      justifyContent: 'flex-end',
      width: 60,
      height: 60,
      marginRight: 10
    },
    linkIcon: {
      color: colors.black
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
)
