import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    subtitle: {
      fontWeight: 'bold'
    },
    largeImage: {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  })
)
