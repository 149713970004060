import React from 'react'
import { createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import Img4 from 'assets/images/img-4.png'
import Reiki from 'assets/images/reiki.png'
import Yoga from 'assets/images/yoga.png'
import Main from 'assets/images/main.png'
import { colors } from 'styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& > .carousel': {
        '& > ul.control-dots': {
          '& > .dot': {
            height: 15,
            width: 15,
            [theme.breakpoints.down('md')]: {
              width: 10,
              height: 10
            }
          },
          '& > .selected': {
            backgroundColor: colors.boldOrage
          }
        }
      }
    },
    nextArrow: {
      boxShadow: 'box-shadow: 10px 10px 20px 1px rgba(0,0,0,1)',
      borderRadius: '50%',
      width: 60,
      height: 60,
      borderColor: colors.boldOrage,
      borderStyle: 'solid',
      borderWidth: 2,
      backgroundColor: colors.whiteOpacity,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
      right: '5%',
      top: '50%',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: colors.whiteOpacity
      },
      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 40
      }
    },
    backArrow: {
      borderRadius: '50%',
      width: 60,
      height: 60,
      borderColor: colors.boldOrage,
      borderStyle: 'solid',
      borderWidth: 2,
      backgroundColor: colors.whiteOpacity,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'absolute',
      left: '5%',
      top: '50%',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: colors.whiteOpacity
      },
      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 40
      }
    },
    slide: {
      maxHeight: 600,
      [theme.breakpoints.down('md')]: {
        maxHeight: 200
      }
    }
  })
)
/**
 * Featured section
 *
 * @returns {JSX.Element}
 */

const Featured: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container item>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        className={classes.container}
        renderArrowNext={clickHandler => (
          <IconButton onClick={clickHandler} className={classes.nextArrow}>
            <ArrowForwardIosIcon style={{ color: colors.boldOrage }} />
          </IconButton>
        )}
        renderArrowPrev={clickHandler => (
          <IconButton onClick={clickHandler} className={classes.backArrow}>
            <ArrowBackIosIcon style={{ color: colors.boldOrage }} />
          </IconButton>
        )}
      >
        <div className={classes.slide}>
          <img src={Main} />
        </div>
        <div className={classes.slide}>
          <img src={Yoga} />
        </div>
        <div className={classes.slide}>
          <img src={Reiki} />
        </div>
        <div className={classes.slide}>
          <img src={Img4} />
        </div>
      </Carousel>
    </Grid>
  )
}

export { Featured }
