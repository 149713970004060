import React from 'react'
import { Grid } from '@material-ui/core'

import Testimonials from './Testimonials'
import Contact from './Contact'
import Featured from './Featured'

import useStyles from './styles'

/**
 * Home page
 *
 * @returns {JSX.Element}
 */

const Home: React.FC = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Featured />
      <Testimonials />
      <Contact />
    </Grid>
  )
}

export { Home }
