import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormControl, Grid, Hidden, InputLabel, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { validEmailRegex } from 'utils/regex'
import ContactIllustration from 'assets/images/illustration-mision.svg'
import DecoratedTitle from 'components/DecoratedTitle'
import InputField from 'components/InputField'
import Button from 'components/Button'
import Select from 'components/Select'
import SectionWrapper from 'components/SectionWrapper'

import { colors } from 'styles'
import useStyles from './styles'
import fetcher from 'utils/fetcher'
import api from 'api'
import { IContactForm } from 'types'

const CONTACT_OPTIONS = [
  { value: '1', label: 'Conocer más acerca de las actividades.' },
  { value: '2', label: 'Conocer más acerca de los planes.' },
  { value: '3', label: 'Conocer más sobre los servicios.' }
]

/**
 * Contact section
 *
 * @returns {JSX.Element}
 */

const Contact: React.FC = (): JSX.Element => {
  const [notification, setNotification] = useState<boolean>()
  // Form Control
  const { register, handleSubmit, control, errors } = useForm<IContactForm>()

  // Methods
  const onSubmit = async ({ name, phone, email, reason }: IContactForm) => {
    const response = await fetcher(api.send, 'POST', {
      name,
      phone,
      email,
      subject: CONTACT_OPTIONS[reason].label
    })
    if (!response.error) {
      setNotification(true)
    }
  }

  const handleClose = () => setNotification(false)

  const classes = useStyles()

  return (
    <SectionWrapper color={colors.white} sectionId="contacto">
      <DecoratedTitle title="Contáctanos" />

      <Grid container item xs={12} style={{ justifyContent: 'center' }}>
        <Grid container item xs={12} md={6}>
          <Typography variant="body1">
            ¿Quieres que te contemos más acerca de nuestro centro o simplemente deseas que nos
            pongamos en contacto contigo para aclarar todas tus dudas? Rellena nuestro formulario:
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.contactForm}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={12} style={{ width: '100%' }}>
                <InputField
                  inputRef={register({ required: true })}
                  placeholder="Escribe tu nombre completo"
                  label="¿Cuál es tu nombre?"
                  name="name"
                  id="name"
                  errorMessage={errors.name && 'Nombre es requerido'}
                />
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <InputField
                  inputRef={register({
                    required: true
                  })}
                  placeholder="000 000 0000"
                  label="Teléfono"
                  name="phone"
                  id="phone"
                  type="tel"
                  errorMessage={errors.phone && (errors.phone.message || 'Teléfono es requerido')}
                />
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <InputField
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: validEmailRegex,
                      message: 'Correo electrónico inválido'
                    }
                  })}
                  placeholder="usuario@email.com"
                  label="Correo electrónico"
                  name="email"
                  id="email"
                  errorMessage={
                    errors.email && (errors.email.message || 'Correo electrónico es requerido')
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel
                    id="reason"
                    style={{
                      color: 'black',
                      fontWeight: 500,
                      transform: 'translate(0, 1.5px) scale(0.9)'
                    }}
                  >
                    Seleccione el motivo de su consulta
                  </InputLabel>
                  <Controller
                    as={Select}
                    rules={{ required: true }}
                    name="reason"
                    control={control}
                    options={CONTACT_OPTIONS}
                    defaultValue="1"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.submitButton}>
                <Button variant="contained" type="submit">
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid container item xs={6}>
            <img src={ContactIllustration} />
          </Grid>
        </Hidden>
      </Grid>
      <Snackbar open={notification} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Tu mensaje ha sido entregado con éxito. Te estaremos contactando a la brevedad posible.
        </Alert>
      </Snackbar>
    </SectionWrapper>
  )
}

export { Contact }
